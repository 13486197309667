import LayoutAdmin from '@/layouts/LayoutAdmin'
import myTable from './table'

export default {
  data() {
    return {
      kata_kunci: null,
      pilih_cif: null,
      search: '',
      loadingTb: false,
      listCif: [],
      tbData: [],
      getData: [],
      setId: null,
      listRegister: [],
      listProject: [],

      listEvent: [],
      list_usersTypes: [],
      list_userGroup: [],
      porsi:0,
      isShow:false,
      tableDetail:[],
      setDefault:null,
      projectId:null

    }
  },
  created() {
    this.$emit(`update:layout`, LayoutAdmin);
  },
  mounted() {
    // this.getTable()

    

    this.G_GetAny('sakti/event').then(res => {
      let resData = res.data.content

      let listData = [];
      let arrSub = {};

      resData.forEach(v => {

        if (v.asDefault) {
          this.setDefault = v.saktiEventId
        }
          arrSub = {};

          arrSub['text'] = v.saktiEventNm;
          arrSub['value'] = v.saktiEventId;

          listData.push(arrSub);
      });

      this.listEvent = listData;

      this.$refs.filter_event.setValue(this.setDefault)
      this.getTable()
      

    })

    this.G_GetAny('porsiLoan/projek/1').then(res => {
      let resData = res.data.content

      let listData = [];
      let arrSub = {};

      resData.forEach(v => {
          arrSub = {};

          arrSub['text'] = v.projekNm;
          arrSub['value'] = v.projekId;

          listData.push(arrSub);
      });

      this.listProject = listData;

    })
   
  },
  components: {
    myTable
  },
  methods: {
    getTable() {
      this.isShow = true
      this.loadingTb = true
      let filter = '/0'
      if (this.$refs.filter_event.getValue()) {
        filter = '/'+this.$refs.filter_event.getValue()
      }

      this.G_GetAny('porsiLoan/projek'+filter).then(res => {
        console.log(res);
        this.loadingTb = false
        this.tbData = res.data.content
      })
    },
    
    edit(datas) {
     
      console.log(datas);
      this.$refs.modal.open("Edit Data Porsi Loan", 600)
      this.setId = datas.porsiRegisterId
        this.$nextTick(() => {
          this.porsi = datas.porsi
          this.$refs.projectId.setValue(this.projectId)
          this.getRegister(this.projectId,datas.registerId)
        });
    },
    add() {
      if (this.$refs.form) {
        this.$refs.form.reset()
        this.$refs.form.resetValidation()
      }
      this.setId = null
      this.getData = []
      this.$refs.modal.open("Tambah Data Porsi Loan", 600)

    },
    store() {
      let myForm = document.getElementById('formData');
      let formData = new FormData(myForm);
     

      
      if (this.setId) {
        formData.append('porsiRegisterId',this.setId)
        formData.append('porsi',this.porsi)
      }else{
        formData.append('saktiEventId',this.$refs.filter_event.getValue())
        formData.append('registerId',this.$refs.registerId.getValue())
        formData.append('porsi',this.porsi)
      }


      if (this.$refs.form.validate()) {
          this.$refs.modal.loading()

          if (this.setId) {
            this.G_PutAny('porsiLoan', formData).then(res => {
              if (!res.status) {
                this.detail(this.projectId);
                this.$snotify.success('Berhasil Mengubah Data');
                this.$refs.modal.close()
              }else{
                this.$snotify.warning('Terjadi Kesalahan');
              }
            })
            .finally(() => {
              this.$refs.modal.loadingEnd()
              
            });
          }else{
            console.log('add');
            this.G_PostAny('porsiLoan', formData).then(res => {
              if (!res.status) {
                this.getTable();
                this.$snotify.success('Berhasil Menyimpan Data');
                this.$refs.modal.close()
              }else{
                this.$snotify.warning('Terjadi Kesalahan');
              }
            })
            .finally(() => {
              this.$refs.modal.loadingEnd()
              
            });
          }

         
      }
      else {
        this.$snotify.info('Lengkapi Data Terlebih Dahulu');
      }
    },
    detail(projectId){

      this.projectId = projectId 
      this.G_GetAny(`/porsiLoan/projek/${this.$refs.filter_event.getValue()}/${projectId}`).then(res => {
        console.log(res);
        this.tableDetail = res.data.content
        this.$refs.modalDetail.open("Detail Data Porsi Loan", 1000)
      
      })
    },
    getRegister(id,registerId){
      this.G_GetRef('register','?projekId='+id).then(data => {
        this.listRegister = data;
      }).finally(() => {
        if(this.setId){
          this.$refs.registerId.setValue(registerId)
        }
        
      });;
    }
  
  }
}