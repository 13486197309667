<template>
    <div>
        <v-data-table :headers="headers" :items="tbData" :single-expand="singleExpand" :search="search"
            :loading="loadingTb" loading-text="Sedang Memuat Data..." :expanded.sync="expanded" item-key="id">

             <template v-slot:item.noRow="{ item }" >
                 {{tbData.map(function(x) {return x.userId; }).indexOf(item.userId)+1}}
             </template>

             <!-- <template v-slot:item.counterOs="{item}">
                <div class="p-2 text-right">
                    {{G_numFormat(item.counterOs)}}
                </div>
            </template>
               <template v-slot:item.clOs="{item}">
                <div class="p-2 text-right">
                    {{G_numFormat(item.clOs)}}
                </div>
            </template>
             <template v-slot:item.selisih="{item}">
                <div class="p-2 text-right">
                    {{G_numFormat(item.selisih)}}
                </div>
            </template> -->

            <template v-slot:item.actions="{ item }">
                <v-menu transition="slide-x-transition" bottom right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="#fafafa" class="btn-table"  fab depressed x-small v-bind="attrs" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>

                    <v-list flat dense elevation="1">
                        <!-- <v-subheader>Action</v-subheader> -->
                        <!-- <v-list-item-group color="primary">
                              <v-list-item @click="edit(item.userId)">
                                <v-list-item-icon class="mr-3">
                                    <v-icon small>mdi-pencil</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Edit</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group> -->
                            <v-list-item-group color="primary">
                              <v-list-item @click="detail(item.projekId)">
                                <v-list-item-icon class="mr-3">
                                    <v-icon small>mdi-clipboard</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Detail</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-menu>
            </template>
        </v-data-table>

      

    </div>
</template>
<script>
    export default {
        data() {
            return {
                expanded: [],
                singleExpand: false,
                  headers: [
                    { text: 'No', value: 'noRow',sortable: false,width: "1%" },
                    { text: 'ID Projek', value: 'projekId' },
                    { text: 'Nama Projek', value: 'projekNm' },
                    { text: 'Banyak Loan', value: 'countRegister' },
                    { text: 'Total Persen Porsi', value: 'totalPorsi' },
                    { text: 'Act', value: 'actions', sortable: false,width: "2%" },
                ],
            }
        },
        mounted(){
           
        },
        methods: {
        
            getTable(){
                this.$emit('getTable');
            },
             edit(id){
                this.$emit('edit',id);
            },
             detail(id){
                this.$emit('detail',id);
            },
          
            
        },

        props: ['tbData', 'search', 'loadingTb'],
    }
</script>